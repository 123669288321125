import { MdLogin, MdOpenInBrowser } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { update } from "../../redux/slicers/home-slice";

const Card = ({
  label,
  logo,
  url,
}: {
  label: string;
  logo: string;
  url: string;
}) => {
  const home = useAppSelector((state) => state.home);
  const dispatch = useAppDispatch();

  return (
    <div
      className="group w-[220px] h-[240px] flex flex-col items-center shadow-xl shadow-gray-200 rounded-lg border border-gray-100 cursor-pointer hover:bg-[#2f558607] hover:hover:scale-105 transition"
      onClick={() =>
        dispatch(
          update({
            ...home,
            showLogin: true,
            selectedFrame: {
              url: url,
              title: label,
            },
          })
        )
      }
    >
      <div className="group-hover:opacity-30 flex flex-col w-full h-full items-center">
        <div className="h-[180px] flex items-center">
          <img src={logo} className="max-w-[125px] max-h-[125px]" />
        </div>
        <div className="flex flex-1 items-center justify-center w-full border bg-[#2f5586] rounded-b-lg">
          <span
            className={`text-white text-center ${
              label.length >= 20 ? "text-xs lg:text-base" : "text-sm lg:text-lg"
            }`}
          >
            {label}
          </span>
        </div>
      </div>

      <div className="absolute hidden group-hover:flex flex-col items-center gap-2 justify-center h-[240px] w-[220px]">
        {/* <button
          className="rounded-full bg-[#4985d2] hover:bg-[#37649f] px-4 py-2 text-xs text-white flex items-center gap-1"
          onClick={() => dispatch(update({ ...home, showLogin: true }))}
        >
          Login
          <MdLogin />
        </button> */}

        {/* <button
          className="rounded-full bg-white hover:bg-[#f9fafc] px-4 py-2 text-xs text-[#4985d2] border-1 border-[#4985d2] flex items-center gap-1"
          onClick={() => {
            window.open(url);
          }}
        >
          Go to page
          <MdOpenInBrowser />
        </button> */}
      </div>
    </div>
  );
};

export default Card;
