import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type sessionState = { username: string };

const initialState: sessionState = { username: "" };

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    login(state, action: PayloadAction<sessionState>) {
      Object.assign(state, action.payload);
    },
    logout(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { login, logout } = sessionSlice.actions;
export default sessionSlice.reducer;
