import { MdLogout } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../hooks";
import { update } from "../redux/slicers/home-slice";
import { logout } from "../redux/slicers/session-slice";
import { useState } from "react";

const Navbar = () => {
  const dispatch = useAppDispatch();
  const home = useAppSelector((state) => state.home);
  const session = useAppSelector((state) => state.session);
  const [collapse, setCollapse] = useState(false);

  const menus = [
    {
      title: "Petroport",
      url: "http://petroport.isce.petrokimia-gresik.com/",
    },
    {
      title: "WMS Inbound",
      url: "http://wms.isce.petrokimia-gresik.com/login",
    },
    { title: "2CE", url: "http://2ce.isce.petrokimia-gresik.com/" },
    { title: "DTMS", url: "http://dtms.isce.petrokimia-gresik.com/" },
    {
      title: "WMS Outbound",
      url: "http://wms.isce.petrokimia-gresik.com/login",
    },
  ];
  return (
    <nav className="bg-slate-50 border-gray-200">
      <div className="flex flex-wrap items-center justify-between mx-auto p-2 px-4">
        <img
          src="/assets/logo-isce.png"
          className="h-6 mr-3"
          alt="Flowbite Logo"
        />
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={() => setCollapse(!collapse)}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`w-full md:block md:w-auto ${!collapse ? "hidden" : ""}`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-4 md:mt-0 md:border-0 md:items-center">
            {menus.map((menu, i) => {
              return (
                <li key={i}>
                  <div
                    className={`block text-sm py-2 pl-3 pr-4 rounded text-[#2f5586] md:px-4 md:py-1 cursor-pointer ${
                      menu.title === home.selectedFrame.title
                        ? "bg-[#4985d2] text-white"
                        : "bg-transparent"
                    }`}
                    onClick={() => {
                      setCollapse(false);
                      dispatch(
                        update({
                          ...home,
                          selectedFrame: menu,
                        })
                      );
                    }}
                  >
                    {menu.title}
                  </div>
                </li>
              );
            })}

            <li className="flex items-center mt-4 md:mt-auto">
              <div
                className="text-xs rounded-lg text-white flex items-center gap-2 bg-black md:bg-[#447cc5] hover:bg-[#2f5586] p-2 cursor-pointer"
                onClick={() => {
                  setCollapse(false);
                  dispatch(
                    update({
                      ...home,
                      selectedFrame: {
                        title: "",
                        url: "",
                      },
                    })
                  );
                  dispatch(logout());
                }}
              >
                {session.username} <MdLogout />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
