import { ReactElement } from "react";

const ContainerCard = ({
  label,
  children,
  className,
}: {
  className?: string;
  label: string;
  children: ReactElement;
}) => {
  return (
    <div className={`flex flex-col items-center gap-y-[20px] ${className}`}>
      <h2 className="font-bold text-base 2xl:text-xl text-black flex items-center cursor-pointer">
        ➥ {label}
      </h2>
      <div className="flex gap-[10px]">{children}</div>
    </div>
  );
};

export default ContainerCard;
