import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type homeState = {
  showLogin: boolean;
  selectedFrame: {
    title: string;
    url: string;
  };
  loginForm: {
    username: string;
    password: string;
    loading: boolean;
  };
};

const initialState: homeState = {
  showLogin: false,
  selectedFrame: {
    title: "",
    url: "",
  },
  loginForm: {
    username: "",
    password: "",
    loading: false,
  },
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    update(state, action: PayloadAction<homeState>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { update } = homeSlice.actions;
export default homeSlice.reducer;
