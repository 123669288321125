import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import "./App.css";
import ContainerCard from "./components/home/ContainerCard";
import Card from "./components/home/Card";
import { useAppDispatch, useAppSelector } from "./hooks";
import Navbar from "./components/Navbar";
import { login } from "./redux/slicers/session-slice";
import { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { BiKey, BiUser } from "react-icons/bi";

//to store all the interval ids
const intervalIds = [] as any[];

//overriding the origianl
const setInterval = function (fn: () => void, delay: number) {
  const id = window.setInterval(fn, delay);
  //storing the id of each interval
  intervalIds.push(id);
  return id;
};

//clear all interval
const clearAllInterval = function () {
  while (intervalIds.length) {
    clearInterval(intervalIds.pop());
  }
};

function App() {
  const home = useAppSelector((state) => state.home);
  const session = useAppSelector((state) => state.session);
  const dispatch = useAppDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      const iframeEl: any = document.getElementById("iframe");
      switch (event.data) {
        case "isNotAuthenticated":
          switch (iframeEl.src) {
            case "http://petroport.isce.petrokimia-gresik.com/":
              iframeEl.contentWindow.postMessage(
                {
                  action: "login",
                  username: "2166529",
                  password: "Petrokimia@2023!",
                },
                "*"
              );
              break;
            case "http://wms.isce.petrokimia-gresik.com/login":
              iframeEl.contentWindow.postMessage(
                {
                  action: "login",
                  username: "2166529",
                  password: "2166529",
                },
                "*"
              );
              break;

            case "http://2ce.isce.petrokimia-gresik.com/":
              iframeEl.contentWindow.postMessage(
                { action: "login", username: 2166529, password: 2166529 },
                "*"
              );
              break;
            case "http://dtms.isce.petrokimia-gresik.com/":
              iframeEl.contentWindow.postMessage(
                {
                  action: "login",
                  username: "lolapel@gmail.com",
                  password: "lolapel12345",
                },
                "*"
              );
              break;
          }
          break;
        case "isAuthenticated":
          loader(0, true);
          break;
      }
    });
  }, []);

  useEffect(() => {
    clearAllInterval();
    setPercentage(0);
    loader(100);
  }, [home.selectedFrame]);

  useEffect(() => {
    if (percentage === 0) {
      clearAllInterval();
      loader(100);
    }
    // else if (percentage === 25) {
    //   clearAllInterval();
    //   loader(150);
    // } else if (percentage === 50) {
    //   clearAllInterval();
    //   loader(200);
    // } else if (percentage === 75) {
    //   clearAllInterval();
    //   loader(250);
    // }
  }, [percentage]);

  const loader = (s: number, finish = false) => {
    const obj: any = document.querySelector(".preloader");
    if (!!obj && obj.classList) {
      obj.classList.add("show");
      let p = percentage;
      setInterval(() => {
        p = p + 1;
        setPercentage(p);
        if (p >= 99) {
          if (finish) {
            obj.classList.remove("show");
            clearAllInterval();
          } else {
            clearAllInterval();
            setInterval(() => {
              const iframeEl: any = document.getElementById("iframe");
              if (!!iframeEl) iframeEl.src += "";
              clearAllInterval();
            }, 3000);
          }
        }
      }, s);
    }
  };

  if (!!session.username && !!home.selectedFrame && !!home.selectedFrame.url)
    return (
      <div className="w-full h-[100vh]">
        <Navbar />
        <div className="preloader">
          <div className="preloader_inner">{percentage}%</div>
        </div>
        <iframe
          id="iframe"
          src={home.selectedFrame.url}
          className="w-full h-[calc(100vh-48px)]"
        />
      </div>
    );

  if (!!session.username && !!home.selectedFrame && !home.selectedFrame.url)
    return (
      <div className="flex items-center flex-col p-4">
        <header className="flex flex-col items-center">
          <img src="/assets/logo-isce.png" className="w-[275px]" />
        </header>
        <section className="mt-8 md:mt-12 flex flex-col items-center gap-[30px]">
          <div className="grid gap-[35px] grid-cols-1 md:grid-cols-3">
            <ContainerCard label="Proses Logistik">
              <Card
                label="Petroport"
                logo="/assets/logo-petroport.png"
                url="http://petroport.isce.petrokimia-gresik.com/"
              />
            </ContainerCard>

            <ContainerCard label="Bahan Baku">
              <Card
                label="WMS Inbound"
                logo="/assets/logo-wms-inbound.png"
                url="http://wms.isce.petrokimia-gresik.com/login"
              />
            </ContainerCard>

            <ContainerCard label="Penjualan">
              <Card
                label="2CE"
                logo="/assets/logo-2ce.png"
                url="http://2ce.isce.petrokimia-gresik.com/"
              />
            </ContainerCard>
          </div>

          <div className="grid gap-[35px] grid-cols-1">
            <div className={`flex flex-col items-center gap-y-[20px]`}>
              <h2 className="font-bold text-base 2xl:text-xl text-black flex items-center cursor-pointer">
                ➥ Pengiriman
              </h2>
              <div className="grid gap-[35px] grid-cols-1 md:grid-cols-2">
                <Card
                  label="DTMS"
                  logo="/assets/logo-dtms.png"
                  url="http://dtms.isce.petrokimia-gresik.com/"
                />
                <Card
                  label="WMS Outbound"
                  logo="/assets/logo-wms.png"
                  url="http://wms.isce.petrokimia-gresik.com/login"
                />
                {/* <Card
              label="Portpass"
              logo="/assets/logo-portpass.png"
              url="http://portpass.petrokimia-gresik.com/login"
            /> */}
              </div>
            </div>
          </div>
        </section>

        {/* {home.showLogin && (
          <Login
            login={() => {
              dispatch(login({ username: "admin@demo" }));
              dispatch(update({ ...home, showLogin: false }));
              // dispatch(
              //   update({
              //     ...home,
              //     showLogin: false,
              //     openFrameUrl: "http://localhost:3200",
              //   })
              // );
            }}
            close={() => dispatch(update({ ...home, showLogin: false }))}
          />
        )} */}
      </div>
    );

  return (
    <div
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 p-4 w-full h-full overflow-x-hidden overflow-y-auto max-h-full m-auto flex items-center justify-center bg-[#2f5586ab]"
    >
      <div
        className="relative w-full max-w-md max-h-full"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="relative bg-white rounded-lg shadow">
          <div className="p-6 space-y-8">
            <div className="space-y-2 items-center">
              <img
                src="/assets/logo-isce.png"
                className="w-[225px] m-auto mb-8"
              />
              <h3 className="font-bold text-xl">Login</h3>
              <h5 className="font-light text-sm text-gray-400">
                Login to your ISCE account
              </h5>
            </div>

            <div className="space-y-4">
              {error && <span className="text-xs text-red-600">{error}</span>}

              <Input
                placeholder="Username"
                prefix={<BiUser color="rgb(0 0 0 / 45%)" />}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Input.Password
                placeholder="Password"
                prefix={<BiKey color="rgb(0 0 0 / 45%)" />}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                className="w-full bg-[#00b96b]"
                type="primary"
                onClick={async () => {
                  setLoading(true);
                  const sleep = () => {
                    return new Promise((resolve) => {
                      setTimeout(() => {
                        resolve(true);
                      }, 1000);
                    });
                  };
                  await sleep();

                  if (username !== "admin@demo" || password !== "admin") {
                    setError("Incorrect username or password");
                  } else {
                    setError("");
                    dispatch(login({ username: "admin@demo" }));
                  }
                  setLoading(false);
                }}
                disabled={loading}
              >
                Login
              </Button>
            </div>

            <div className="space-y-2">
              <h3 className="font-bold text-xl">Don't have an account?</h3>
              <h5 className="font-light text-sm text-gray-400">
                No need to worry.
              </h5>
              <h5 className="font-light text-sm text-gray-400">
                You can contact the administrator
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
