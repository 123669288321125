// https://github.com/rahsheen/react-native-template-redux-typescript
// https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/

import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionSlice from "./slicers/session-slice";
import thunk from "redux-thunk";
import homeSlice from "./slicers/home-slice";

const persistConfig = {
  key: "root",
  storage: storage,
  // blacklist: ["home"],
  whitelist: ["session", "home"],
};

const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    session: sessionSlice,
    home: homeSlice,
  })
);

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
